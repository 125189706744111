<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-date"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker v-if="modal2" locale="id" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Name input -->
        <b-form-group
          id="input-group-po-no"
          label="No PO:"
          label-for="input-po-no"
        >
          <b-form-input
            id="input-po-no"
            v-model="form.po_no"
            placeholder="No PO"
          ></b-form-input>
          <small class="text-danger">{{ error.po_no }}</small>
        </b-form-group>

        <!-- Name input -->
        <b-form-group id="input-group-vendor">
          <label for="input-vendor"
            >Kepada:</label
          >
          <treeselect
            v-model="form.vendor_id"
            :multiple="false"
            :options="vendors"
            @select="setVendor"
          />
          <small class="text-danger">{{ error.vendor_id }}</small>
        </b-form-group>

        <!-- Input Opening Sentence -->
        <b-form-group id="input-group-opening-sentence">
          <label for="input-opening-sentence"
            >Kalimat Pembuka:</label
          >
          <b-form-textarea
            id="input-opening-sentence"
            v-model="form.opening_sentence"
            rows="3"
            placeholder="Kalimat Pembuka"
            @keyup="setOpeningSentence"
          ></b-form-textarea>
          <small class="text-danger">{{ error.opening_sentence }}</small>
        </b-form-group>

        <!-- Input Batch No -->
        <b-form-group id="input-group-batch-no">
          <label for="input-vendor">Item</label>
          <div class="row">
            <div class="col-md-3">
              <b-form-input
                id="input-batch-no"
                v-model="medicine_collection_form.item"
                placeholder="Nama Item"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{ error_item.item }}</small> -->
            </div>
            <div class="col-md-3">
              <b-form-input
                id="input-quantity-batch"
                v-model="medicine_collection_form.quantity"
                placeholder="Kuantitas"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{
                error_item.quantity
              }}</small> -->
            </div>
            <div class="col-md-3">
              <b-form-input
                id="input-price-batch"
                v-model="formatedSalesPrice"
                placeholder="Harga"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{
                error_item.price
              }}</small> -->
            </div>
            <div class="col-md-3">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Batch"
                type="button"
                @click="setItem"
                v-if="medicine_collection_form.quantity != '' && medicine_collection_form.price != '' && medicine_collection_form.item != ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-info"
                type="button"
                @click="setItem"
                disabled
                v-if="medicine_collection_form.quantity == '' || medicine_collection_form.price == '' || medicine_collection_form.item == ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
          <small class="text-danger">{{ error.items }}</small>
        </b-form-group>

      <div class="bg-light">
        <table class="table">
          <tr>
            <th>No</th>
            <th>Items</th>
            <th>Quantity</th>
            <th>Price/case(IDR)</th>
            <th>Total Prices</th>
            <th>Aksi</th>
          </tr>
          <tr v-for="(mdc, index) in medicine_collection" :key="mdc.id">
            <td>{{index + 1}}</td>
            <td>{{ mdc.item }}</td>
            <td>{{ mdc.quantity }}</td>
            <td>{{ mdc.price }}</td>
            <td>{{ mdc.total_price }}</td>
            <td>
              <b-button
                size="sm"
                class="btn-danger"
                v-b-tooltip.hover
                title="Hapus Item"
                type="button"
                @click="removeItem(mdc.item)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </td>
          </tr>
        </table>
        </div>

        <!-- Input Discount -->
        <b-form-group
          id="input-group-discount"
          label="Diskon(%):"
          label-for="input-discount"
        >
          <b-form-input
            id="input-discount"
            v-model="form.discount"
            placeholder="Diskon"
          >
          </b-form-input>
          <small class="text-danger">{{ error.discount }}</small>
        </b-form-group>

        <!-- Input PPN -->
        <b-form-group
          id="input-group-ppn"
          label="PPN(%):"
          label-for="input-ppn"
        >
          <b-form-input id="input-ppn" v-model="form.ppn" placeholder="PPN">
          </b-form-input>
          <small class="text-danger">{{ error.ppn }}</small>
        </b-form-group>

        <!-- Input Closing Sentence -->
        <b-form-group id="input-group-closing-sentence">
          <label for="input-closing-sentence"
            >Kalimat Penutup:</label
          >
          <b-form-textarea
            id="input-closing-sentence"
            v-model="form.closing_sentence"
            rows="3"
            placeholder="Kalimat Penutup"
          ></b-form-textarea>
          <small class="text-danger">{{ error.closing_sentence }}</small>
        </b-form-group>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/purchase-orders')"
        >
          Batal
        </b-button>
      </b-form>
    </v-app>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      modal2: false,
      // Error
      error: {
        date: "",
        po_no: "",
        vendor_id: "",
        vendor_name: "",
        items: "",
        discount: "",
        ppn: "",
        opening_sentence: "",
        closing_sentence: "",
      },
      // other
      vendors: [],
      medicine_collection: [],
      medicine_collection_str: [],
      medicine_collection_form: {
        item: "",
        quantity: "",
        price: "",
        total_price: "",
      },
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      tester: "",
      opening_sentence_write: false,
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    setOpeningSentence(){
      console.log(this.opening_sentence_write)
      this.opening_sentence_write = true
    },

    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      console.log("vendor option")
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
        this.vendors.unshift({ label: 'Pilih Supplier', id: '', isDisabled: true })
      }
    },

    resetItemOnNew(){
      this.medicine_collection_form.quantity = ""
      this.medicine_collection_form.price = ""
      this.medicine_collection_form.total_price = ""
      this.medicine_collection_form.item = ""
    },

    removeItem($item) {
      console.log("remove batch", $item);
      let a;
      for (a = 0; a < this.medicine_collection.length; a++) {
        if (this.medicine_collection[a].item == $item) {
          this.medicine_collection.splice(a, 1);
          console.log("hasil", this.medicine_collection)
        }
      }
    },

    setVendor(evt){
      console.log("evt", evt)
      this.form.vendor_id = evt.id
      this.form.vendor_name = evt.label
      // if(this.opening_sentence_write == false){
      //   this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
      // }
      // this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
    },

    setItem(){
      // let price = parseInt(this.medicine_collection_form.price)
      // this.medicine_collection_form.total_price = this.medicine_collection_form.quantity * this.medicine_collection_form.price
      // console.log("yang akan di input", this.medicine_collection_form);
      // this.medicine_collection.push(this.medicine_collection_form);
      this.medicine_collection_form.total_price = this.medicine_collection_form.quantity * this.medicine_collection_form.price.replace(".", "")
      this.medicine_collection_form.total_price = parseInt(this.medicine_collection_form.total_price).toLocaleString("id-ID")
      let clone = { ...this.medicine_collection_form };
      this.medicine_collection.push(clone);
      console.log("hasil", this.medicine_collection)
      this.resetItemOnNew()
    },

    async formOnsubmit() {
      if(this.medicine_collection.length > 0){
      this.medicine_collection_str = await JSON.stringify(this.medicine_collection);
      this.form.items = await this.medicine_collection_str;
      console.log("form", this.form);
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/purchase-orders");
      }
      }else if(this.medicine_collection.length == 0){
        Swal.fire({
                title: "Gagal",
                text: "Item tidak boleh kosong",
                icon: "warning",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.error.items = "Item tidak boleh kosong"
      }
    },
  },

  mounted(){
    this.getVendorOption()
  },

  computed: {
    formatedSalesPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.medicine_collection_form.price = newValue.toLocaleString('id-ID')
        } else {
          this.medicine_collection_form.price = ''
        }
      },
      get() {
        return this.medicine_collection_form.price
      }
    }
  },
};
</script>

